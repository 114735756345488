import React from "react";
import { Routes, Route } from "react-router-dom";

// Layout
import Home from "./pages/index/Home";

//Enlaces
import ProcesosElectorales from "./pages/enlaces/ProcesosElectorales/ProcesosElectorales";
import HistorialPE from "./pages/enlaces/ProcesosElectorales/HistorialPE";
import PE20152016 from "./pages/enlaces/ProcesosElectorales/PE20152016";
import PEE2017 from "./pages/enlaces/ProcesosElectorales/PEE2017";
import PE2018 from "./pages/enlaces/ProcesosElectorales/PE2018";
import PE20202021 from "./pages/enlaces/ProcesosElectorales/PE20202021";
import PE20232024 from "./pages/enlaces/ProcesosElectorales/PE20232024";
import Normatividad from "./pages/enlaces/Normatividad";
import Galeria from "./pages/enlaces/Galeria/Galeria";
import GaleriaTot from "./pages/enlaces/Galeria/GaleriaTot";

import Oic from "./pages/enlaces/OIC/Oicc";
import GaleriaOIC from "./pages/enlaces/OIC/GaleriaOIC";
import Infografias from "./pages/enlaces/Infografias";
import MemoriasElectorales from "./pages/enlaces/MemoriasElectorales";
import MetasMSPEN from "./pages/enlaces/MetasMSPEN";
import PartidosPoliticos from "./pages/enlaces/PartidosPoliticos";

import ProcesOrdin from "./pages/enlaces/ProcesOrdin";
import ProcesExtraordin from "./pages/enlaces/ProcesExtraordin";

import PersonasDiscapacidad from "./pages/enlaces/PersonasDiscapacidad";
import Informes from "./pages/enlaces/Informes";

import InformesAnualesITE from "./pages/enlaces/InformesAnualesITE";

//Acerca de ITE
import FinesInstitucionales from "./pages/acercaITE/FinesInstitucionales";
import Historia from "./pages/acercaITE/Historia";
import PrincipiosRectores from "./pages/acercaITE/PrincipiosRectores";
import EstructuraOrganica from "./pages/acercaITE/EstructuraOrganica";

//Consejo General
import Integracion from "./pages/consejoGeneral/Integracion";
import Comisiones from "./pages/consejoGeneral/Comisiones";
import Jge from "./pages/consejoGeneral/Jgee";
import Estructura from "./pages/consejoGeneral/Estructura";

//Acuerdos INE
import AcuerdosINE from "./pages/acuerdos/acuerdosINE/AcuerdosINE";
import AcuerdosTableINE from "./pages/acuerdos/acuerdosINE/AcuerdosTableINE";
import AcuerdosINE2025 from "./pages/acuerdos/acuerdosINE/AcuerdosINE2025";

//Acuerdos ITE
import AcuerdosAnteriores from "./pages/acuerdos/AcuerdosAnteriores";
import AcuerdosTableITE from "./pages/acuerdos/acuerdosITE/AcuerdosTableITE";
import Acuerdos2001 from "./pages/acuerdos/acuerdosITE/Acuerdos2001";
import Acuerdos2002 from "./pages/acuerdos/acuerdosITE/Acuerdos2002";
import AcuerdosITE from "./pages/acuerdos/acuerdosITE/AcuerdosITE";
import Acuerdos2025 from "./pages/acuerdos/acuerdosITE/Acuerdos2025";

//Demarcaciones
import Acuerdos from "./pages/demarcaciones/Acuerdos";
import Distritacion2015 from "./pages/demarcaciones/Distritacion2015";
import Distritacion2022 from "./pages/demarcaciones/Distritacion2022";
import Mapas from "./pages/demarcaciones/Mapas";

//Transparencia
import Transparencia from "./pages/transparencia/Transparencia";

//Transparencia Artículo 63
import Articulo63 from "./pages/transparencia/art63/Articulo63";
import Art63Table from "./pages/transparencia/art63/Art63Table";
import Art632024 from "./pages/transparencia/art63/Art632024";

//Transparencia Artículo 67
import Articulo67 from "./pages/transparencia/art67/Articulo67";
import Art67Table from "./pages/transparencia/art67/Art67Table";
import Art672024 from "./pages/transparencia/art67/Art672024";

//Transparencia Artículo 94
import Articulo94 from "./pages/transparencia/art94/Articulo94";
import Art94Table from "./pages/transparencia/art94/Art94Table";

import InformesAnuales from "./pages/transparencia/transp/InformesAnuales";
import AvisosPrivacidad from "./pages/transparencia/transp/AvisosPrivacidad";
import FormatoSolicitud from "./pages/transparencia/transp/FormatoSolicitud";
import Poa from "./pages/transparencia/transp/Poaa";
import InformacionFinanciera from "./pages/transparencia/transp/InformacionFinanciera";
import InformacionContable from "./pages/transparencia/transp/InformacionContable";

import Section from "./pages/transparencia/transp/planes/Section";
import Planes2024 from "./pages/transparencia/transp/planes/Planes2024";
import Planes2025 from "./pages/transparencia/transp/planes/Planes2025";

import Convenios from "./pages/transparencia/transp/convens/Convenios";
import Convenios2014 from "./pages/transparencia/transp/convens/Convenios2014";
import Convenios2015 from "./pages/transparencia/transp/convens/Convenios2015";
import Convenios2016 from "./pages/transparencia/transp/convens/Convenios2016";
import Convenios2017 from "./pages/transparencia/transp/convens/Convenios2017";
import Convenios2018 from "./pages/transparencia/transp/convens/Convenios2018";
import Convenios2019 from "./pages/transparencia/transp/convens/Convenios2019";
import Convenios2020 from "./pages/transparencia/transp/convens/Convenios2020";
import Convenios2021 from "./pages/transparencia/transp/convens/Convenios2021";
import Convenios2022 from "./pages/transparencia/transp/convens/Convenios2022";
import Convenios2023 from "./pages/transparencia/transp/convens/Convenios2023";
import Convenios2024 from "./pages/transparencia/transp/convens/Convenios2024";
import Convenios2025 from "./pages/transparencia/transp/convens/Convenios2025";

import PreguntasFrecuentes from "./pages/transparencia/PreguntasFrecuentes";
import DatosAbiertos from "./pages/transparencia/DatosAbiertos";

//Comunicacion Social
import BoletinesTable from "./pages/comunicacionSocial/boletines/BoletinesTable";

import MonitoreosTable from "./pages/comunicacionSocial/monitoreos/MonitoreosTable";
import Monitoreos2024 from "./pages/comunicacionSocial/monitoreos/Monitoreos2024";
import MonitoreosPELE2024 from "./pages/comunicacionSocial/monitoreos/MonitoreoPELE2024";

import ITEConecta from "./pages/comunicacionSocial/ITEConecta";
import EnCorto from "./pages/comunicacionSocial/EnCorto";
//Cultura Cívica
import ActividadesLudicas from "./pages/culturaCivica/ActividadesLudicas";
import EleccionesEscolares from "./pages/culturaCivica/EleccionesEscolares";
import ParlamentoInfantil from "./pages/culturaCivica/ParlamentoInfantil";
import ParlamentoJuvenil from "./pages/culturaCivica/ParlamentoJuvenil";
import ValoresDemocracia from "./pages/culturaCivica/ValoresDemocracia";

//Archivo
import InfografiasArchivo from "./pages/archivo/InfografiasArchivo";
import InstrumentosArchivisticos from "./pages/archivo/InstrumentosArchivisticos";
import InstrumentosNormativos from "./pages/archivo/InstrumentosNormativos";
import PlaneacionArchivistica from "./pages/archivo/PlaneacionArchivistica";

//Convocatorias

import ConvocatoriasApartado from "./pages/ConvocatoriasApartado/ConvocatoriasApartado";

//Micrositios
import Reps from "./pages/reps/Repss";

//Memoria Electoral
import MemoriaElectoral20202021 from "./pages/memElect2023/MemoriaElectoral20202021";
import EstradosElectronicos from "./pages/EstradosElectronicos/EstradosElectronicos";

import Licitaciones from "./pages/licitaciones/Licitaciones";

import NotFound from "./layout/NotFound";

const AppRoutes = () => (
  <Routes>
    <Route path="*" element={<NotFound />} />

    <Route exact path="/" element={<Home />} />

    {/* Enlaces */}
    <Route path="/ProcesosElectorales" element={<ProcesosElectorales />} />
    <Route path="/PE20152016" element={<PE20152016 />} />
    <Route path="/PEE2017" element={<PEE2017 />} />
    <Route path="/PE2018" element={<PE2018 />} />
    <Route path="/PE20202021" element={<PE20202021 />} />
    <Route path="/PE20232024" element={<PE20232024 />} />
    <Route path="/HistorialPE" element={<HistorialPE />} />
    <Route path="/Normatividad" element={<Normatividad />} />
    <Route path="/Galeria" element={<Galeria />} />
    <Route path="/Galeria2016" element={<GaleriaTot year="2016" />} />
    <Route path="/Galeria2017" element={<GaleriaTot year="2017" />} />
    <Route path="/Galeria2018" element={<GaleriaTot year="2018" />} />
    <Route path="/Galeria2019" element={<GaleriaTot year="2019" />} />
    <Route path="/OIC" element={<Oic />} />
    <Route path="/GaleriaOIC" element={<GaleriaOIC />} />
    <Route path="/Infografias" element={<Infografias />} />
    <Route path="/MemoriasElectorales" element={<MemoriasElectorales />} />
    <Route path="/MetasMSPEN" element={<MetasMSPEN />} />
    <Route path="/PartidosPoliticos" element={<PartidosPoliticos />} />

    <Route path="/proceso-ordinario" element={<ProcesOrdin />} />
    <Route path="/proceso-extraordinario" element={<ProcesExtraordin />} />

    <Route path="/PersonasDiscapacidad" element={<PersonasDiscapacidad />} />
    <Route path="/Informes" element={<Informes />} />
    <Route path="/InformesAnualesITE" element={<InformesAnualesITE />} />

    {/* Acerca de ITE */}
    <Route path="/FinesInstitucionales" element={<FinesInstitucionales />} />
    <Route path="/Historia" element={<Historia />} />
    <Route path="/PrincipiosRectores" element={<PrincipiosRectores />} />
    <Route path="/EstructuraOrganica" element={<EstructuraOrganica />} />

    {/* Consejo General */}
    <Route path="/Integracion" element={<Integracion />} />
    <Route path="/Comisiones" element={<Comisiones />} />
    <Route path="/JGE" element={<Jge />} />
    <Route path="/Estructura" element={<Estructura />} />

    {/* Acuerdos ITE */}
    <Route path="/AcuerdosAnteriores" element={<AcuerdosAnteriores />} />
    <Route path="/AcuerdosINE" element={<AcuerdosINE />} />
    <Route path="/Acuerdos1998" element={<AcuerdosTableITE year="1998" />} />
    <Route path="/Acuerdos2001" element={<Acuerdos2001 />} />
    <Route path="/Acuerdos2002" element={<Acuerdos2002 />} />
    <Route path="/Acuerdos2003" element={<AcuerdosTableITE year="2003" />} />
    <Route path="/Acuerdos2004" element={<AcuerdosTableITE year="2004" />} />
    <Route path="/Acuerdos2005" element={<AcuerdosTableITE year="2005" />} />
    <Route path="/Acuerdos2006" element={<AcuerdosTableITE year="2006" />} />
    <Route path="/Acuerdos2007" element={<AcuerdosTableITE year="2007" />} />
    <Route path="/Acuerdos2008" element={<AcuerdosTableITE year="2008" />} />
    <Route path="/Acuerdos2009" element={<AcuerdosTableITE year="2009" />} />
    <Route path="/Acuerdos2010" element={<AcuerdosITE year="2010" />} />
    <Route path="/Acuerdos2011" element={<AcuerdosITE year="2011" />} />
    <Route path="/Acuerdos2012" element={<AcuerdosTableITE year="2012" />} />
    <Route path="/Acuerdos2013" element={<AcuerdosITE year="2013" />} />
    <Route path="/Acuerdos2014" element={<AcuerdosITE year="2014" />} />
    <Route path="/Acuerdos2015" element={<AcuerdosTableITE year="2015" />} />
    <Route path="/Acuerdos2016" element={<AcuerdosTableITE year="2016" />} />
    <Route path="/Acuerdos2017" element={<AcuerdosTableITE year="2017" />} />
    <Route path="/Acuerdos2018" element={<AcuerdosTableITE year="2018" />} />
    <Route path="/Acuerdos2019" element={<AcuerdosTableITE year="2019" />} />
    <Route path="/Acuerdos2020" element={<AcuerdosTableITE year="2020" />} />
    <Route path="/Acuerdos2021" element={<AcuerdosTableITE year="2021" />} />
    <Route path="/Acuerdos2022" element={<AcuerdosTableITE year="2022" />} />
    <Route path="/Acuerdos2023" element={<AcuerdosTableITE year="2023" />} />
    <Route path="/Acuerdos2024" element={<AcuerdosTableITE year="2024" />} />
    <Route path="/Acuerdos2025" element={<Acuerdos2025 year="2025" />} />

    {/* Acuerdos INE */}
    <Route path="/AcuerdosINE2015" element={<AcuerdosTableINE year="2015" />} />
    <Route path="/AcuerdosINE2016" element={<AcuerdosTableINE year="2016" />} />
    <Route path="/AcuerdosINE2020" element={<AcuerdosTableINE year="2020" />} />
    <Route path="/AcuerdosINE2021" element={<AcuerdosTableINE year="2021" />} />
    <Route path="/AcuerdosINE2022" element={<AcuerdosTableINE year="2022" />} />
    <Route path="/AcuerdosINE2023" element={<AcuerdosTableINE year="2023" />} />
    <Route path="/AcuerdosINE2024" element={<AcuerdosTableINE year="2024" />} />
    <Route path="/AcuerdosINE2025" element={<AcuerdosINE2025 year="2025" />} />

    {/* Demarcaciones */}
    <Route path="/Acuerdos" element={<Acuerdos />} />
    <Route path="/Distritacion2015" element={<Distritacion2015 />} />
    <Route path="/Distritacion2022" element={<Distritacion2022 />} />
    <Route path="/Mapas" element={<Mapas />} />

    {/* Transparencia */}
    <Route path="/Transparencia" element={<Transparencia />} />

    {/* Artículo 63 */}
    <Route path="/Articulo63" element={<Articulo63 />} />
    <Route path="/Art632017" element={<Art63Table year="2017" />} />
    <Route path="/Art632018" element={<Art63Table year="2018" />} />
    <Route path="/Art632019" element={<Art63Table year="2019" />} />
    <Route path="/Art632020" element={<Art63Table year="2020" />} />
    <Route path="/Art632021" element={<Art63Table year="2021" />} />
    <Route path="/Art632022" element={<Art63Table year="2022" />} />
    <Route path="/Art632023" element={<Art63Table year="2023" />} />
    <Route path="/Art632024" element={<Art632024 />} />

    {/* Artículo 67 */}
    <Route path="/Articulo67" element={<Articulo67 />} />
    <Route path="/Art672017" element={<Art67Table year="2017" />} />
    <Route path="/Art672018" element={<Art67Table year="2018" />} />
    <Route path="/Art672019" element={<Art67Table year="2019" />} />
    <Route path="/Art672020" element={<Art67Table year="2020" />} />
    <Route path="/Art672021" element={<Art67Table year="2021" />} />
    <Route path="/Art672022" element={<Art67Table year="2022" />} />
    <Route path="/Art672023" element={<Art67Table year="2023" />} />
    <Route path="/Art672024" element={<Art672024 />} />

    {/* Artículo 94 */}
    <Route path="/Articulo94" element={<Articulo94 />} />
    <Route path="/Art942020" element={<Art94Table year="2020" />} />
    <Route path="/Art942021" element={<Art94Table year="2021" />} />

    {/* Transparencia General */}
    <Route path="/InformesAnuales" element={<InformesAnuales />} />

    <Route path="/AvisosPrivacidad" element={<AvisosPrivacidad />} />
    <Route path="/FormatoSolicitud" element={<FormatoSolicitud />} />
    <Route path="/POA" element={<Poa />} />
    <Route path="/InformacionFinanciera" element={<InformacionFinanciera />} />
    <Route path="/InformacionContable" element={<InformacionContable />} />

    <Route path="/Section" element={<Section />} />
    <Route path="/Planes2024" element={<Planes2024 />} />
    <Route path="/Planes2025" element={<Planes2025 />} />

    <Route path="/Convenios" element={<Convenios />} />
    <Route path="/Convenios2014" element={<Convenios2014 />} />
    <Route path="/Convenios2015" element={<Convenios2015 />} />
    <Route path="/Convenios2016" element={<Convenios2016 />} />
    <Route path="/Convenios2017" element={<Convenios2017 />} />
    <Route path="/Convenios2018" element={<Convenios2018 />} />
    <Route path="/Convenios2019" element={<Convenios2019 />} />
    <Route path="/Convenios2020" element={<Convenios2020 />} />
    <Route path="/Convenios2021" element={<Convenios2021 />} />
    <Route path="/Convenios2022" element={<Convenios2022 />} />
    <Route path="/Convenios2023" element={<Convenios2023 />} />
    <Route path="/Convenios2024" element={<Convenios2024 />} />
    <Route path="/Convenios2025" element={<Convenios2025 />} />

    <Route path="/Infografias" element={<Infografias />} />
    <Route path="/PreguntasFrecuentes" element={<PreguntasFrecuentes />} />
    <Route path="/DatosAbiertos" element={<DatosAbiertos />} />

    {/* Comunicación Social */}
    <Route path="/Boletines2015" element={<BoletinesTable year="2015" />} />
    <Route path="/Boletines2016" element={<BoletinesTable year="2016" />} />
    <Route path="/Boletines2017" element={<BoletinesTable year="2017" />} />
    <Route path="/Boletines2019" element={<BoletinesTable year="2019" />} />
    <Route path="/Boletines2020" element={<BoletinesTable year="2020" />} />
    <Route path="/Boletines2021" element={<BoletinesTable year="2021" />} />
    <Route path="/Boletines2022" element={<BoletinesTable year="2022" />} />
    <Route path="/Boletines2023" element={<BoletinesTable year="2023" />} />
    <Route path="/Boletines2024" element={<BoletinesTable year="2024" />} />
    <Route path="/Boletines2025" element={<BoletinesTable year="2025" />} />

    <Route path="/Monitoreos2015" element={<MonitoreosTable year="2015" />} />
    <Route path="/Monitoreos2016" element={<MonitoreosTable year="2016" />} />
    <Route path="/Monitoreos2017" element={<MonitoreosTable year="2017" />} />
    <Route path="/Monitoreos2018" element={<MonitoreosTable year="2018" />} />
    <Route path="/Monitoreos2020" element={<MonitoreosTable year="2020" />} />
    <Route path="/Monitoreos2021" element={<MonitoreosTable year="2021" />} />
    <Route path="/Monitoreos2023" element={<MonitoreosTable year="2023" />} />
    <Route path="/Monitoreos2024" element={<Monitoreos2024 />} />
    <Route path="/MonitoreosPELE2024" element={<MonitoreosPELE2024 />} />

    <Route path="/ITEConecta" element={<ITEConecta />} />
    <Route path="/EnCorto" element={<EnCorto />} />

    {/* Cultura Cívica */}
    <Route path="/ActividadesLudicas" element={<ActividadesLudicas />} />
    <Route path="/EleccionesEscolares" element={<EleccionesEscolares />} />
    <Route path="/ParlamentoInfantil" element={<ParlamentoInfantil />} />
    <Route path="/ParlamentoJuvenil" element={<ParlamentoJuvenil />} />
    <Route path="/ValoresDemocracia" element={<ValoresDemocracia />} />

    {/* Archivo */}
    <Route path="/InfografiasArchivo" element={<InfografiasArchivo />} />
    <Route
      path="/InstrumentosArchivisticos"
      element={<InstrumentosArchivisticos />}
    />
    <Route
      path="/InstrumentosNormativos"
      element={<InstrumentosNormativos />}
    />
    <Route
      path="/PlaneacionArchivistica"
      element={<PlaneacionArchivistica />}
    />

    {/* Convocatorias */}
    <Route path="/ConvocatoriasApartado" element={<ConvocatoriasApartado />} />

    {/* Estrados Electrónicos */}
    <Route path="/EstradosElectronicos" element={<EstradosElectronicos />} />

    {/* Licitaciones */}
    <Route path="/licitaciones" element={<Licitaciones />} />

    {/* Micrositios */}
    <Route path="/REPS" element={<Reps />} />

    {/* MemoriaElectoral20202021 */}
    <Route
      path="/MemoriaElectoral20202021"
      element={<MemoriaElectoral20202021 />}
    />
  </Routes>
);

export default AppRoutes;
